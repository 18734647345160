<template>
    <v-container class="fill-height" fluid>
      <v-layout align-center justify-center>
        <v-col cols="12">
          <h2>サービス終了のお知らせ</h2>
          <p></p>
          <p>平素よりレコセレをご愛顧いただき、誠にありがとうございます。</p>
          <p>レコセレは「受取店舗をハブとした、再配達率0%の新しい物流網の構築」をコンセプトに、運営してまいりましたが、昨今の事業環境から、お客様にご満足いただけるサービスを継続的にご提供することが難しいと判断いたしまして、2024年3月16日にサービスを終了させていただきました。</p>
          <p>サービス開始から多くのお客様のご愛顧をいただき、誠にありがとうございました。</p>
        </v-col>
      </v-layout>
      <BottomNavigation true></BottomNavigation>
    </v-container>
  </template>
  
<script>
  import BottomNavigation from '@/components/BottomNavigation.vue';
  export default {
    name: 'End',
    components: {
      BottomNavigation,
    },
  }
</script>
  
  <style lang="scss" scoped></style>